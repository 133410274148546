export interface StyleClosetTheme {
  colors: { [key in keyof typeof colors]: string };
  numbers:  { [key in keyof typeof numbers]: string };
  breakpoints: { [key in keyof typeof breakpoints]: string };
  links:  { [key in keyof typeof links]: string };
  store:  { [key in keyof typeof store]: number };

}

const colors = {
  primary: '#E29094',
  secondary: '#F5A0A4',
  tertiary: '#D68386',
  quartiary: '#F5ADB2',
  blackLow: '#515151',
  navLink: '#720455',
  subTitles: '#D68386',
  filters: '#FFE0E8',
  check:'#1CAC21',
  black: '#000',
  gray: '#656565',
  white: '#fff',
  agendaments: '#fdb42b',
};

const numbers = {
  whatsapp: '+5547996696640',
}

const links = {
  instagram: 'allemdfe',
  maps: 'https://maps.app.goo.gl/1RXSZkxAZiBSx68M6'
}

const store = {
  open: 8,
  close: 18,
}

const breakpoints = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1280px',
};


const theme: StyleClosetTheme = {
  colors,
  numbers,
  breakpoints,
  links,
  store,
};

export { theme };
