import * as S from './style'; 

const BarraDoCallTo = () => {

  return (
    <S.Container>
      <S.Container2 />
    </S.Container>
  );
};

export default BarraDoCallTo;
