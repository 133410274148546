// Updated styles in style.js file
import styled from 'styled-components/macro';

export const Title = styled.span`
  font-weight: 600;
`;

export const Container = styled.div`
  width: 100%;
  align-items: center;
  flex-direction: column;
  z-index: 1;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    width: 100%;
    margin-top: 0px;
  }
`;

export const NavLink = styled.a`
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.6px;
  padding: 1.5rem;
  min-width: 60px;
  margin: 0 0.6rem;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.colors.navLink};
  }

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const Image = styled.img`
  width: 25px;
`;

export const A = styled.a`
  text-decoration: none;
`;

export const NavStart = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.a`
  margin-right: 1rem;
`;

export const LogoImage = styled.img`
width: 140px;
height: 140px;
  }

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.tablet}) {
        margin-left: 28%;
  }
`;

export const HeroLogo = styled.img`
  width: 150px;
  margin-top: -40px;
`;

export const HeroIcon = styled.svg`
  width: 25px;
  height: 25px;
  display: flex;
`;

export const DropdownLink = styled.a`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0;
  border-radius: 7px;
  transition: 0.1s ease-in-out;
  color: ${({ theme }) => theme.colors.gray};
`;

export const ImageIcon = styled.img`
  padding: 0.5rem;
  background-color: #eeeeee;
  border-radius: 10px;
  width: 35px;
  height: 45px;
`;

export const DropdownLinkTitle = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

export const DropdownLinkParagraph = styled.p`
  font-size: 0.8rem;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const HomeButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 20vh;
`;

export const HeroCloseMobileMenu = styled.div`
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const MenuMobileContent = styled.div`
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
`;

export const MenuDesktopContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  height: 90px;
  padding: 1rem 3rem;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    padding: 1rem;
  }

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    padding: 1.2rem;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    display: none;
  }
`;

export const Burguer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Location = styled.div`
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius: 18px;
  flex-direction: column;
  padding: 10px 10px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: auto;
  font-size: 15px;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    border-radius: 10px;
  }
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    padding: 10px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const LocationInner = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  display: flex;
  padding: auto;
  pointer-events: none;
  align-items: center;
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    display: flex;
    padding: auto;
    width: 100%;
  }
`;
export const LocationIcon = styled.span`
  margin-right: auto;
  pointer-events: none;
  padding: auto;
  align-items: center;
  margin-left: 5px;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    width: 100%;
  }
`;

export const Store = styled.span`
  color: ${({ theme }) => theme.colors.black};
  padding: 10px;
  border-radius: 5px;
  width: auto;
  font-size: 12px;
  text: center;
  text-decoration: none !important;
  pointer-events: none;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    margin-top: 5px;
    width: 100%;
    margin-left: 0px;
  }
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    margin-top: 5px;
    width: 100%;
    margin-left: 0px;
    border-radius: 5px;
    font-size: 12px;
    padding: auto;
  }
`;
