import { KeyboardEvent, useEffect, useState } from 'react';
import formatPrice from 'utils/formatPrice';
import confetti from 'canvas-confetti';
import { IProduct } from 'models';
import React from 'react';

import { useCart } from 'contexts/cart-context';

import * as S from './style';
import CustomSelect from './customSelect';

interface IProps {
  product: IProduct;
}

const Product = ({ product }: IProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>();
  const [productPrice, setProductPrice] = useState<number>(product.price);

  const { openCart, addProduct } = useCart();

  const {
    sku,
    title,
    price,
    installments,
    currency_id,
    description,
    information,
    currency_format,
    is_schedule,
    waiting_schedule,
    has_product_type,
    types
  } = product;

  const formattedPrice = formatPrice(productPrice, currency_id);

  let productInstallment;

  if (installments) {
    const installmentPrice = productPrice / installments;

    productInstallment = (
      <S.Installment>
        <span>ou {installments} x </span>
        <b>
          {currency_format}
          {formatPrice(installmentPrice, currency_id)}
        </b>
      </S.Installment>
    );
  }

  const handleAddProduct = () => {
    const productToAdd = {
      ...product,
      quantity: 1,
      price: productPrice,
      selectedSubtype: selectedOption?.value || '',
    };
    addProduct(productToAdd);
    openCart();
    confetti({
      particleCount: 100,
      spread: 70,
    });
  };

  const handleAddProductWhenEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter' || event.code === 'Space') {
      openCart();
    }
  };

  interface Option {
    value: string;
    label: string;
    price: number;
  }

  useEffect(() => {
    if (has_product_type && selectedOption) {
      setProductPrice(selectedOption.price);
    } else if (has_product_type && !selectedOption) {
      setProductPrice(types[0]?.values[0]?.price || price);
    } else {
      setProductPrice(price);
    }
  }, [selectedOption, has_product_type, types, product.price]);

  const isConditionTrue = (is_schedule && (
    <S.StopperAvailable>Agendamento c/ {waiting_schedule}</S.StopperAvailable>
  )) || <S.StopperSchedule>Pronta entrega</S.StopperSchedule>;

  return (
    <S.Container onKeyUp={handleAddProductWhenEnter} sku={sku} tabIndex={1}>
      {isConditionTrue}
      <S.Image alt={title} />
      <S.Title>{title}</S.Title>
      {has_product_type ? (
        (types.map((type) => (
          <div key={type.id}>
            <h3>{type.name}</h3>
            <CustomSelect
              options={type.values.map((value) => ({
                label: value.id,
                value: value.value,
                price: value.price,
              }))}
              onSelect={(selectedOption) => setSelectedOption(selectedOption)}
            />
          </div>
        )))
      ) : null}
      <S.Description>{description}</S.Description>
      <S.Information>{information}</S.Information>
      <S.Price>
        <S.Val>
          <small>{currency_format}</small>
          <b>{formattedPrice.substring(0, formattedPrice.length - 3)}</b>
          <span>{formattedPrice.substring(formattedPrice.length - 3)}</span>
        </S.Val>
        {productInstallment}
      </S.Price>
      {has_product_type && !selectedOption ? (
        <S.BuyButton
          disabled={!selectedOption}
          onClick={handleAddProduct}
          tabIndex={-1}
          className="relative overflow-visible rounded-full hover:-translate-y-1 px-12 shadow-xl bg-background/30 after:content-[''] after:absolute after:rounded-full after:inset-0 after:bg-background/40 after:z-[-1] after:transition after:!duration-500 hover:after:scale-150 hover:after:opacity-0"
        >
          Selecione uma opção acima
        </S.BuyButton>) : (
        <S.BuyButton
          onClick={handleAddProduct}
          tabIndex={-1}
          className="relative overflow-visible rounded-full hover:-translate-y-1 px-12 shadow-xl bg-background/30 after:content-[''] after:absolute after:rounded-full after:inset-0 after:bg-background/40 after:z-[-1] after:transition after:!duration-500 hover:after:scale-150 hover:after:opacity-0"
        >
          Adicionar ao carrinho
        </S.BuyButton>
      )}
    </S.Container>
  );
};

export default Product;
