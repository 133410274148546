import Carrousel from './Carrousel';
import Navbar from './Navbar';
import BottomNavbar from 'components/Layout/BottomNavbar';

import * as S from './style';

const Hero: React.FC = () => {
  return (
    <S.Container>
      <S.ContainerNav>
        <Navbar />
      </S.ContainerNav>
      <S.ContainerBottomNav>
        <BottomNavbar />
      </S.ContainerBottomNav>
      <Carrousel />
    </S.Container>
  );
};

export default Hero;
