import styled from 'styled-components/macro';

export const BuyButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: #ffff;
  padding: 10px 0;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  border: 0;
  border-radius: 10px;
  transition: background-color 0.2s;
  
  &:focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.secondary};
  }
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.tablet}) {
    font-size: 14px;
}
`;

interface IImage {
  alt: string;
}
export const Image = styled.div<IImage>``;

interface IContainer {
  sku: number | string;
}

const getImageUrl1 = (sku: any) =>
  process.env.REACT_APP_NODE_ENV === 'production'
    ? `${process.env.REACT_APP_AWS_S3_URL_PREFIX}/${process.env.REACT_APP_COMPANY_ID}-${sku}-1.webp`
    : `${require(`static/products/${sku}-1-product.webp`)}`;

const getImageUrl2 = (sku: any) =>
  process.env.REACT_APP_NODE_ENV === 'production'
    ? `${process.env.REACT_APP_AWS_S3_URL_PREFIX}/${process.env.REACT_APP_COMPANY_ID}-${sku}-2.webp`
    : `${require(`static/products/${sku}-2-product.webp`)}`;

export const Container = styled.div<IContainer>`
    position: relative;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  
    padding: 10px;
    margin-bottom: 30px;d
    cursor: default;
    outline: none;

    height: auto;
  
    border-radius: 10px;
    transition: ease-in-out 0.7s;
  
    &:focus-visible {
      outline: 3px solid ${({ theme }) => theme.colors.secondary};
    }
  
    @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
    breakpoints.tablet}) {
      width: 270px;
    }
  
    ${Image} {
      width: 100%;
      height: 220px;
      position: relative;
  
      ${({ sku }) =>
    sku &&
    `background-image: url(${getImageUrl1(sku)});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
  
        ::before {
          content: '';
          display: block;
          position: absolute;
          background: #eee;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
  
        @media only screen and (min-width: ${({
      theme: { breakpoints },
    }: {
      theme: { breakpoints: { tablet: string } };
    }) => breakpoints.tablet}) {
          height: 240px;
        }
      
        @media only screen and (max-width: ${({
      theme: { breakpoints },
    }: {
      theme: { breakpoints: { mobile: string } };
    }) => breakpoints.mobile}) {
          height: 204px;
        }
      `}
    }
  
    &:hover {
      ${Image} {
        ${({ sku }) =>
    sku &&
    `background-image: url(${getImageUrl2(sku)});
          `}
      }
  
      transform: translateY(-10px);
  
      ${BuyButton} {
        background-color: ${({ theme }) => theme.colors.secondary};
      }
    }
  `;

export const StopperAvailable = styled.div`
  position: absolute;
  color: #ececec;
  top: 10px;
  right: 10px;
  padding: 4px;
  font-size: 0.7em;
  background: ${({ theme }) => theme.colors.agendaments};
  cursor: default;
  z-index: 1;
`;

export const StopperSchedule = styled.div`
position: absolute;
color: #ececec;
top: 10px;
right: 10px;
padding: 5px;
font-size: 0.7em;
background: ${({ theme }) => theme.colors.check};
cursor: default;
z-index: 1;
`;

export const Title = styled.p`
  position: relative;
  padding: 0 20px;
  height: 45px;

  &::before {
    content: '';
    width: 20px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.secondary};
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -10px;
  }
`;

export const Description = styled.p`
  position: relative;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.blackLow};
  padding: 0 10px;
  height: auto;
  line-height: 15px;
  margin-bottom: 20px;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobile}) {
        font-size: 12.5px;
    }
`;

export const Information = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.black};
  height: 25px;
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobile}) {
    font-size: 12.5px;
  }
`;

export const Price = styled.div`
  height: 60px;
  .val {
    b {
      font-size: 1.5em;
      margin-left: 5px;
    }
  }

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobile}) {
    height: 65px;
    }
`;


export const Val = styled.p`
  margin: 0;
  b {
    font-size: 1.5em;
    margin-left: 5px;
  }
`;

export const Installment = styled.p`
  margin: 0;
  color: #9c9b9b;
`;
