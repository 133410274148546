import styled from 'styled-components/macro';

export const Container = styled.div`
  width: auto;
  margin-left: 12px;
  cursor: default;
  outline: none;
  height: auto;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  position: relative;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.desktop}) {
    height: auto;
  }
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    height: 7vh;
  }
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobile}) {
  height: auto;
}
`;

export const Radio = styled.div`
  width: auto;
  padding-left: 7px;
  zIndex: 9999;
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
        padding-left: 7px;
  }
  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobile}) {
      width: auto;
}
`;
