import React, { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import * as S from './styleCustomSelect';

interface Option {
  value: string;
  label: string;
  price: number;
}

interface CustomSelectProps {
  options: Option[];
  onSelect: (option: Option) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, onSelect }) => {
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleSelectOption = (option: Option) => {
    onSelect(option);
  };

  return (
    <S.Container>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={selectedValue}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio size="small" />}
            label={option.value}
            onClick={() => {
              setSelectedValue(option.value);
              handleSelectOption(option);
            }}
            sx={{
              color: 'black',
              '& .MuiSvgIcon-root': {
                color: 'pink'
              },
            }}
          />
        ))}
      </RadioGroup>
    </S.Container>
  );
};

export default CustomSelect;
