/* eslint-disable jsx-a11y/img-redundant-alt */
import * as S from './style';
import actionbarImage from 'static/actionbar1.png'; // Certifique-se de substituir 'actionbarImage.jpg' pelo caminho correto da sua imagem

const ActionBar = () => {
  return (
    <S.Container> 
      <S.Content>
        <S.Heading>
          <S.Title>É Sobre Colecionar Memórias</S.Title>
          <S.TitleSub>
            "Cada bolo é mais do que bonito; é a materialização de
            momentos felizes e especiais. Colecione cada foto, cada risada e cada
            memória." 
          </S.TitleSub>
          <S.Button className="template-button">
            <a href="./" style={{ textDecoration: "none" }}>
              <S.LinkModal>Fazer uma encomenda</S.LinkModal>
            </a>
          </S.Button>
        </S.Heading>
        <S.Image>
          <S.ImageWrapper> 
            <img
              alt="Action Bar"
              src={actionbarImage}
            />
          </S.ImageWrapper>
        </S.Image>
      </S.Content>
    </S.Container>
  );
};

export default ActionBar;
