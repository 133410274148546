import * as S from './style';

import React, { useState, MouseEvent, useEffect } from 'react';
import { Badge, Tooltip, Menu, Divider } from 'antd';
import { isOpenStore } from 'utils/storeStatus';
import { theme } from 'commons/style/theme';

import {
  CaretDownOutlined,
  AlignRightOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import './navbar.css';

const Navbar: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openedDropdown, setOpenedDropdown] = useState<string | null>(null);

  const openStore = isOpenStore();

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [mobileMenuOpen]);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleDropdownClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  const handleDropdownToggle = (dropdown: string) => {
    setOpenedDropdown((prevDropdown) =>
      prevDropdown === dropdown ? null : dropdown
    );
  };

  const linkToWhatsapp = `https://api.whatsapp.com/send?phone=${theme.numbers.whatsapp}&text=Olá%2C%20vim%20através%20do%20site%20e%20gostaria%20de%20um%20orçamento`;

  const NavContent = () => {
    return (
      <nav className={`menu ${mobileMenuOpen ? 'show' : ''}`}>
        <ul className="menu-bar">
          <li>
            <button
              className={`nav-link dropdown-btn ${openedDropdown === 'dropdown1' ? 'active' : ''
                }`}
              data-dropdown="dropdown1"
              aria-haspopup="true"
              aria-expanded={openedDropdown === 'dropdown1' ? 'true' : 'false'}
              aria-label="browse"
              onClick={(e) => {
                handleDropdownClick(e);
                handleDropdownToggle('dropdown1');
              }}
            >
              Por que a <b>Alle Bolo Fake?</b>
              <CaretDownOutlined />
            </button>
            <div
              id="dropdown1"
              className={`dropdown ${openedDropdown === 'dropdown1' ? 'active' : ''
                }`}
              onClick={(e: MouseEvent<HTMLDivElement>) => handleDropdownClick}
            >
              <ul role="menu">
                <li>
                  <S.DropdownLinkTitle>
                    {' '}
                    PRINCIPAIS DIFERENCIAIS{' '}
                  </S.DropdownLinkTitle>
                </li>
                <li role="menuitem">
                  <S.DropdownLink href="#best-of-the-day">
                    <S.ImageIcon
                      src="https://cdn-icons-png.flaticon.com/512/4997/4997023.png"
                      alt="carinho em cada detalhe"
                    />
                    <div>
                      <S.DropdownLinkTitle>
                        {' '}
                        Carinho em cada detalhe{' '}
                      </S.DropdownLinkTitle>
                      <S.DropdownLinkParagraph>
                        Todos os nossos produtos são feitos pensando na
                        experiência do cliente.
                      </S.DropdownLinkParagraph>
                    </div>
                  </S.DropdownLink>
                </li>
                <li role="menuitem">
                  <S.DropdownLink href="#featured-streams">
                    <S.ImageIcon
                      src="https://cdn-icons-png.flaticon.com/512/6213/6213218.png"
                      alt="bolos fakes"
                    />
                    <div>
                      <S.DropdownLinkTitle> Bolos Fake </S.DropdownLinkTitle>
                      <S.DropdownLinkParagraph>
                        Trabalhamos com bolos fakes, tudo com extrema qualidade.
                      </S.DropdownLinkParagraph>
                    </div>
                  </S.DropdownLink>
                </li>
                <li role="menuitem">
                  <S.DropdownLink href="#subscriptions">
                    <S.ImageIcon
                      src="https://cdn-icons-png.flaticon.com/512/1592/1592024.png"
                      alt="higiene e segurança"
                    />
                    <div>
                      <S.DropdownLinkTitle>
                        {' '}
                        Nossa Loja Física{' '}
                      </S.DropdownLinkTitle>
                      <S.DropdownLinkParagraph>
                        Venha conhecer nossa loja. Rua Joaquim Falco Uriarte,
                        515 - sala 1
                      </S.DropdownLinkParagraph>
                    </div>
                  </S.DropdownLink>
                </li>
              </ul>

              <ul role="menu">
                <li>
                  <S.DropdownLinkTitle> SOBRE NÓS </S.DropdownLinkTitle>
                </li>

                <li role="menuitem">
                  <S.DropdownLink
                    target="_blank"
                    href="https://www.instagram.com/allemdfe/"
                  >
                    <S.Image
                      src={require('static/logos/instagram.png')}
                      width={25}
                      alt="instagram"
                    />
                    instagram
                  </S.DropdownLink>
                </li>
                <li role="menuitem">
                  <S.DropdownLink target="_blank" href={linkToWhatsapp}>
                    <S.Image
                      src={require('static/logos/whatsapp.png')}
                      width={25}
                      alt="whatsapp"
                    />
                    whatsapp
                  </S.DropdownLink>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <button
              className={`nav-link dropdown-btn ${openedDropdown === 'dropdown2' ? 'active' : ''
                }`}
              data-dropdown="dropdown1"
              aria-haspopup="true"
              aria-expanded={openedDropdown === 'dropdown2' ? 'true' : 'false'}
              aria-label="browse"
              onClick={(e) => {
                handleDropdownClick(e);
                handleDropdownToggle('dropdown2');
              }}
            >
              Produtos Especiais
              <CaretDownOutlined />
            </button>
            <div
              id="dropdown2"
              className={`dropdown ${openedDropdown === 'dropdown2' ? 'active' : ''
                }`}
              onClick={(e: MouseEvent<HTMLDivElement>) => handleDropdownClick}
            >
              <ul role="menu">
                <li>
                  <S.DropdownLinkTitle> Assinatura Mensal </S.DropdownLinkTitle>
                </li>
                <li role="menuitem">
                  <S.DropdownLink href="#branding">
                    Descubra um novo bolo a cada semana na sua empresa :)
                  </S.DropdownLink>
                </li>
                <li role="menuitem">
                  <S.DropdownLink href="#illustrations">
                    Adicione um toque especial à experiência do seu cliente
                    final 😍
                  </S.DropdownLink>
                </li>
              </ul>
              <ul role="menu">
                <li>
                  <S.DropdownLinkTitle> Descubra Mais </S.DropdownLinkTitle>
                </li>
                <li role="menuitem">
                  <S.DropdownLink href="#about-us">Sobre Nós</S.DropdownLink>
                </li>
                <li role="menuitem">
                  <S.DropdownLink href="#contact">
                    Entre em Contato
                  </S.DropdownLink>
                </li>
                <li role="menuitem">
                  <S.DropdownLink href="#special-offers">
                    Ofertas Especiais
                  </S.DropdownLink>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <S.NavLink href="/">Kits para Festas</S.NavLink>
          </li>
          <li>
            <S.NavLink href="/">Nossa História</S.NavLink>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <S.Container>
      <div
        id="mobile-menu"
        className={`hero-mobile-navigation ${mobileMenuOpen ? 'open' : ''}`}
        style={{ display: mobileMenuOpen ? 'block' : 'none' }}
      >
        <S.MenuMobileContent>
          <S.HeroLogo
            alt="pastedImage"
            src={require('static/logos/logo-alle-rosa.png')}
          />
          <NavContent />
          <Menu mode="horizontal" className="hero-links">
            <Divider />
            <S.HomeButtons>
              <a
                className="nav-link button-mobile button-mobile1"
                target="_blank"
                rel="noreferrer"
                href={linkToWhatsapp}
              >
                WhatsApp
              </a>
              <a
                className="nav-link button-mobile button-mobile2"
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=+5547992393892&text=Olá%2C%20vim%20através%20do%20site%20e%20gostaria%20de%20um%20orçamento"
              >
                Entre ou Cadastrar-se
              </a>
            </S.HomeButtons>
          </Menu>
          <S.HeroCloseMobileMenu
            id="close-mobile-menu"
            onClick={handleMobileMenuToggle}
          >
            <S.HeroIcon viewBox="0 0 804.5714285714286 1024">
              <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
            </S.HeroIcon>
          </S.HeroCloseMobileMenu>
        </S.MenuMobileContent>
      </div>
      <S.MenuDesktopContent>
        <header id="nav-menu" aria-label="navigation bar">
          <S.Content>
            <S.NavStart>
              <S.Logo href="/">
                <S.LogoImage
                  src={require('static/logos/logo-alle-branca.png')}
                  alt="Inc Logo"
                />
              </S.Logo>
              <NavContent />
            </S.NavStart>
            <S.RightContainer>
              <S.Location>
                <S.LocationIcon>
                  <Tooltip
                    title={
                      openStore
                        ? 'Fecha as 18:00 :)'
                        : 'Abre amanhã às 8:00 :)'
                    }
                    color={openStore ? 'green' : 'red'}
                  >
                    <Badge
                      className="bubble-dot"
                      dot
                      color={openStore ? 'green' : 'red'}
                    >
                      <NotificationOutlined
                        style={{
                          fontSize: 16,
                          color: theme.colors.tertiary,
                        }}
                      />
                    </Badge>
                    <S.Store>
                      {openStore ? 'LOJA ABERTA' : 'LOJA FECHADA'}
                    </S.Store>
                  </Tooltip>
                </S.LocationIcon>
              </S.Location>
            </S.RightContainer>
            <S.Burguer
              id="open-mobile-menu"
              onClick={handleMobileMenuToggle}
              aria-label="hamburger"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <AlignRightOutlined
                aria-hidden="true"
                className="hero-mobile-menu-button"
              />
            </S.Burguer>
          </S.Content>
        </header>
      </S.MenuDesktopContent>
    </S.Container>
  );
};

export default Navbar;
