import * as S from './style'; 
import { theme } from 'commons/style/theme';


const CallToAction = () => {

  const linkToWhatsapp = `https://api.whatsapp.com/send?phone=${theme.numbers.whatsapp}&text=Olá%2C%20vim%20através%20do%20site%20e%20gostaria%20de%20um%20orçamento`

  return (
    <S.Container>
      <S.Content>
        <S.Header>
          <S.Title>
            Faça sua compra ou alugue conosco, de forma rápida e segura :)
          </S.Title>
          <S.Button>
            <S.ButtonWhatsApp className="template-button">
              <a target='blank' href={linkToWhatsapp}  style={{textDecoration: "none"}}>
                <S.LinkWhatsapp> Falar por WhatsApp </S.LinkWhatsapp>
              </a>
            </S.ButtonWhatsApp>
            <S.ButtonCardapio className="template-button">
              <a target='blank' href="https://www.canva.com/design/DAFf1vxBEw4/fewbt5FSQuqwoicAmVYenA/edit?fbclid=PAAaaloPOE2L2JmKUKjOUQ8OssK_wUO1DlsD_wbKzm6d7PHm8MS3BlGPQ78Fk" style={{textDecoration: "none"}}>
                <S.LinkCardapio> Ver nosso Cardápio </S.LinkCardapio>
              </a>
            </S.ButtonCardapio>
          </S.Button>
        </S.Header>
      </S.Content>
    </S.Container>
  );
};

export default CallToAction;
