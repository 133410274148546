import styled from 'styled-components/macro';

export const Container = styled.section`
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 20px;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.secondary};
  margin-top: 0%;
  text-align: center;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobile}) {
    padding-top: 0px);
    margin-top: 0%;
    height: 10px;
  }
`;

export const Container2 = styled.section`
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 20px;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.quartiary};
  margin-top: 0%;
  text-align: center;

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
    breakpoints.mobile}) {
    padding-top: 20px;
    margin-top: 0%;
  }
`;