import React, { useState } from "react";

import * as S from './style';

import Card from "components/Soluctions/Card/Card";
import { UseModalFacilityOrder } from "contexts/facility-context/useModalFacilityOrder";
import { Container } from "@mui/material";

const Soluctions = () => {
  const [open, setOpen] = useState(false);


  return (
    <Container id="solutions">
      <S.Header>
        <S.Title>
          Como funciona os produtos até chegar no seu local
        </S.Title>
      </S.Header>
      <S.CardContent onClick={() => setOpen(false)}>
        <Card
          Icon={require("static/cardsIcons/01.png")}
          Header="Escolha o Produto"
          IconAlt="Bolo Fake"
        ></Card>
        <Card
          Icon={require("static/cardsIcons/02.png")}
          Header="Adicione ao carrinho"
          IconAlt="Bolo Fake"
        ></Card>
        <Card
          Icon={require("static/cardsIcons/03.png")}
          Header="Faça o pagamento"
          IconAlt="Bolo Fake"
        ></Card>
        <Card
          Icon={require("static/cardsIcons/04.png")}
          Header="Seu endereço"
          IconAlt="Bolo Fake"
        ></Card>
      </S.CardContent>
      {open && <UseModalFacilityOrder setOpen={setOpen} open={open} />}
    </Container>
  );
};

export default Soluctions;
